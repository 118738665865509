'use client';
import classNames from 'classnames';
import { DataLayerProps } from 'eco-design-micro/props/DataLayerProps';
import { LinkProps } from 'eco-design-micro/props/LinkProps';
import EcoStat from 'eco-design-reliance/EcoStat';
import gtm from 'eco-design-reliance/gtm';
import './index.scss';

const uaEventName = 'uaEvent';
// 触发埋点
const handleGTM = (params: DataLayerProps | DataLayerProps[]) => {
    if (Array.isArray(params)) {
        params.forEach((v) => gtm.push(v));
    } else if (params) {
        gtm.push({
            event: uaEventName,
            ...params,
        });
        try {
            const eventObj = {
                ...params,
            };
            EcoStat.push(eventObj);
        } catch (error: any) {}
    }
};
// 设置标签的 target
const setTargetHandle = (target: string, href: string) => {
    if (target) {
        return target;
    }
    const isWebsite = href.includes('www.ecoflow.com'); //品牌官网链接
    const isHomeBattery = href.includes('homebattery.ecoflow.com'); //家储官网链接
    const isNotStartWithHttp = href.startsWith('http'); //非 http 开头的,都以 _self 打开
    if (isWebsite || isHomeBattery || !isNotStartWithHttp) {
        return '_self';
    }
    return '_blank'; //新开tab页
};

/**
 * 通用链接
 *
 * 不会自动添加 locale
 *
 * 会触发埋点
 */
export default function EcoLink({
    href,
    target = '',
    className,
    stopPropagation = false,
    preventDefault = false,
    dataLayer,
    onClick,
    children,
    style,
    ...rest
}: LinkProps) {
    const prefixCls = `eco-link`;
    const classes = classNames(prefixCls, {
        [`${className}`]: className,
    });
    // 打开标签的方式
    const target2 = setTargetHandle(target || '', href || '');
    // 点击事件
    const clickHandle = (e: any) => {
        // 阻止冒泡
        if (stopPropagation) {
            e.stopPropagation();
        }
        // 阻止默认行为
        if (preventDefault) {
            e.preventDefault();
        }
        // 触发埋点
        if (dataLayer) {
            handleGTM(dataLayer);
        }
        if (onClick) {
            onClick(e); //触发回调
        }
    };
    const targetHref = href;

    if (!href) {
        return (
            <div className={classes} rel="noreferrer" onClick={clickHandle} style={style}>
                {children}
            </div>
        );
    }
    //const hrefForShopify = addParamsToLink(href || undefined, locale);
    return (
        <a href={targetHref} className={classes} rel="noreferrer" onClick={clickHandle} style={style} target={target2} {...rest}>
            {children}
        </a>
    );
}
