'use client';
import { Autoplay, Navigation, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import ItemComp from './ItemComp';

export default function Content({ id, data = {}, list = [], initIndex, isEditStatus }: any) {
    const { isAuto } = data;

    return (
        <>
            <Swiper
                className={`swiper-box ${isAuto ? 'auto-play' : ''}`}
                modules={[Scrollbar, Navigation, Autoplay]}
                navigation={true}
                autoplay={isEditStatus ? false : isAuto}
                slidesPerView={'auto'}
                initialSlide={initIndex || 0}
            >
                {list.map((item: any, index: number) => {
                    return (
                        <SwiperSlide key={index}>
                            <ItemComp id={id} data={item} index={index} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </>
    );
}
