import GloLayout from 'lowcode/common/GloLayout';
import styles2 from './bar.module.scss';
import ItemCompOne from './comps/ItemCompOne';
import SwiperList from './comps/SwiperList';
import styles from './index.module.scss';

// 根据时间判断是否去掉
const getDelItemCallback = (item: any, nowTimes: number) => {
    let delFlag = false;
    try {
        if (item.startTime) {
            const itemTimes = new Date(item.startTime).getTime();
            // 还未到时间
            if (nowTimes < itemTimes) {
                delFlag = true;
            }
        }
        if (item.endTime) {
            const itemTimes = new Date(item.endTime).getTime();
            // 超出结束时间
            if (nowTimes > itemTimes) {
                delFlag = true;
            }
        }
    } catch (e: any) {}

    return delFlag;
};
// 将倒计时中没到时间的选项去掉
const getNewListCallback = (list = []) => {
    const nowTimes = Date.now();
    const list2 = JSON.parse(JSON.stringify(list));
    for (let i = 0; i < list.length; i++) {
        const item: any = list[i];
        if (item.barType === 'img_text_countdown') {
            const delFlag = getDelItemCallback(item, nowTimes);
            if (delFlag) {
                list2.splice(i, 1);
            }
        }
    }

    return list2;
};
export default function Content({ id, data = {}, initIndex = 0, isEditStatus = false }: any) {
    const { list = [], prevNextColor = 'rgba(0, 0, 0, 0.10)' } = data;
    const list2 = list; //isEditStatus ? list : getNewListCallback(list);
    const total = list2.length;
    const compStyle = {
        '--glo-bar-top-border-color': prevNextColor,
    };
    return (
        <GloLayout className={`${styles2.bar} ${styles.content} glo-bar-top temp-${id}`} id={id} style={compStyle}>
            <div className="editor-glo-bar-in">
                {total === 1 && <ItemCompOne id={id} data={data} index={0} />}
                {total > 1 && <SwiperList id={id} data={data} list={list2} initIndex={initIndex} isEditStatus={isEditStatus} />}
            </div>
        </GloLayout>
    );
}
